<template>
  <div style="display: contents">
    <CRUDForm
      ref="crudForm"
      :alwaysPostAll="true"
      @save="onFormSave"
      @close="closeForm"
      :readOnly="readOnly"
      :formSize="750"
      :headerText="`Attribute Setup : ${attrId || 'Create New'}`"
      :loading="loading"
      :formActive="value"
    >
      <template v-slot:[`form.content`]="">
        <v-tabs :value="1">
          <v-tab>Info</v-tab>
          <v-tab v-if="['L', 'LM'].includes(values1.ATTR_TYPE)">List of Values</v-tab>
          <v-tab-item :transition="false" :reverse-transition="false">
            <v-row class="pa-4">
              <template v-for="(item, index) in fields">
                <v-col
                  :key="index"
                  :cols="item.cols ? item.cols : '12'"
                  v-if="!(inherited && item.field == 'ADOBE_SYNC')"
                >
                  <InputControl
                    :bind="{ smallChips: item.multiple, row: item.control == 'RADIO' }"
                    :key="index"
                    :attrId="item.field"
                    :label="item.label"
                    :control="item.control"
                    :ref="`input_${item.field}`"
                    :value.sync="values1[item.field]"
                    :attrType="item.type"
                    :attrRef="item.lov"
                    :multiValue="item.multiple"
                    :disabled="inherited && item.field != 'ATTR_LABEL'"
                    :rules="item.validation"
                    @update:value="onInputChange(item.field, $event)"
                  />
                </v-col>
              </template>
              <v-col cols="12">
                <v-card class="pa-2" outlined>
                  <v-label>
                    Options
                  </v-label>
                  <v-card-text>
                    <v-select
                      hide-details="auto"
                      :items="options"
                      v-model="values1['QUERYBIT']"
                      multiple
                      outlined
                      small-chips
                    ></v-select>

                    <div class="d-flex mt-2" v-if="['L', 'LM'].includes(values1.ATTR_TYPE)">
                      <v-checkbox
                        v-model="otherOpt"
                        label="Other"
                        hide-details
                        class="mr-2 mt-1"
                      ></v-checkbox>
                      <template v-if="otherOpt">
                        <v-checkbox
                          class="mr-2 mt-1"
                          v-model="values1.APPEND_LABEL"
                          label="Display Prefix"
                          hide-details
                        ></v-checkbox>
                        <v-text-field
                          class="mr-2"
                          outlined
                          v-model="values1.LABEL_NAME_1"
                          label="Label 1"
                          hide-details
                          dense
                        ></v-text-field>
                        <v-text-field
                          outlined
                          v-model="values1.LABEL_NAME_2"
                          label="Label 2"
                          hide-details
                          dense
                        ></v-text-field>
                      </template>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>

                  <v-btn color="orange lighten-2" text @click="advOpt.active = !advOpt.active">
                    Advance option
                    <v-icon right>{{
                      advOpt.active ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>

                  <v-expand-transition>
                    <div v-show="advOpt.active">
                      <v-card-text>
                        <v-text-field
                          outlined
                          label="Agent UI Display Option "
                          hide-details
                          dense
                          v-model="values1.INPUT_OPTION"
                        ></v-text-field>

                        <v-radio-group
                          v-model="advOpt.formatType"
                          @change="values1.FORMAT = null"
                          row
                          hide-details
                          class="mb-1"
                        >
                          <v-radio
                            v-for="(opt, index) in advOpt.options"
                            :key="index"
                            :label="opt.text"
                            :value="opt.value"
                          ></v-radio>
                        </v-radio-group>
                        <v-select
                          outlined
                          v-show="advOpt.formatType == 'S'"
                          hide-details
                          dense
                          :items="advOpt.formatOpt"
                          v-model="values1.FORMAT"
                        ></v-select>
                        <v-text-field
                          v-show="advOpt.formatType == 'C'"
                          v-model="values1.FORMAT"
                          outlined
                          hide-details
                          dense
                        ></v-text-field>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <v-row class="pa-4">
              <v-col cols="12" class="pa-1">
                <div class="d-flex">
                  <v-checkbox
                    class="mt-1"
                    v-model="toggleAll"
                    :disabled="inherited"
                    @change="onToggleAll('select', $event)"
                  ></v-checkbox>
                  <v-tooltip top v-if="inherited">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">
                        <v-switch
                          class="mt-1"
                          hide-details
                          dense
                          v-on="on"
                          @change="onToggleAll('hide', $event)"
                        ></v-switch>
                      </span>
                    </template>
                    <span>Hide all</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-for="(action, i) in refAction"
                    :key="i"
                    text
                    color="orange "
                    @click="onRefAction(action.action)"
                    :disabled="
                      !(action.mustSelect && action.mustSelect <= selectedRefArr.length) &&
                        !!action.mustSelect
                    "
                    >{{ action.text }}</v-btn
                  >
                </div>
                <draggable :list="attr_ref" handle=".handle">
                  <div class="d-flex" v-for="(ref, i) in attr_ref" :key="i">
                    <v-checkbox
                      class="mt-1"
                      v-if="ref['ATTR_REF_ID']"
                      v-model="selected_ref[ref['ATTR_REF_ID']]"
                      @change="onUpdateToggle"
                      :disabled="inherited"
                    ></v-checkbox>
                    <div v-else class="pa-4 mb-5"></div>
                    <v-tooltip top v-if="inherited">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-switch
                            v-model="ref['HIDDEN']"
                            class="mt-1"
                            hide-details
                            dense
                            v-on="on"
                          ></v-switch>
                        </span>
                      </template>
                      <span>Hide value</span>
                    </v-tooltip>
                    <v-text-field
                      v-model="ref['ATTR_REF_VALUE']"
                      :disabled="ref['HIDDEN']"
                      hide-details
                      dense
                      outlined
                      label="Value Name"
                      class="mr-2"
                    ></v-text-field>
                    <v-text-field
                      v-model="ref['ATTR_REF_LABEL']"
                      :disabled="ref['HIDDEN']"
                      hide-details
                      dense
                      outlined
                      label="Value Label"
                      class="mr-2"
                    ></v-text-field>

                    <v-btn icon class="ml-2 handle">
                      <v-icon>
                        mdi-drag-horizontal-variant
                      </v-icon>
                    </v-btn>
                  </div>
                </draggable>
                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <ActionButton icon="mdi-plus" tooltip="Add new value" @action-click="addNewRow" />
                </div>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </template>
    </CRUDForm>
    <v-dialog :retain-focus="false" v-model="importObj.dialog" max-width="400px">
      <v-card>
        <v-card-text>
          <div v-if="!importObj.importedList" class="py-2">
            <v-file-input
              v-model="importObj.file"
              accept="text/*"
              label="Text file"
              hint="One entry per line"
              persistent-hint
              :rules="[(v) => !!v || 'Required']"
            ></v-file-input>
          </div>
          <div v-else class="py-5">
            The following attribute values have been created:
            <ul class="text--primary">
              <li v-for="ref in importObj.importedList" :key="ref.attr_ref_id">
                {{ ref.attr_ref_id }}: {{ ref.attr_ref_value }}
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="importRef()"
            :disabled="!importObj.file"
            :loading="importObj.saving"
            v-if="!importObj.importedList"
            >Import</v-btn
          >
          <v-btn text @click="importObj.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" v-model="merge.dialog" max-width="400px">
      <v-card :loading="merge.loading">
        <v-card-text>
          <div class="py-5">
            Select Attributes to Merge Into
            <v-radio-group v-model="merge.selected" hide-details>
              <v-radio
                v-for="item in merge.items"
                :key="item.ATTR_REF_ID"
                :label="`${item.text} [${item.count}]`"
                :value="item.ATTR_REF_ID"
              ></v-radio>
            </v-radio-group>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="mergeRef()"
            :disabled="!merge.selected"
            :loading="merge.saving"
            >Merge</v-btn
          >
          <v-btn text @click="merge.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ActionButton from "@/components/util/ActionButton";
import CRUDForm from "@/components/util/CRUD/CRUDForm";
import InputControl from "@/components/util/InputControl";

export default {
  components: {
    ActionButton,
    CRUDForm,
    InputControl,
    draggable
  },
  props: ["campaignId", "attrId", "projectId", "value"],
  computed: {
    inherited() {
      return this.attrId && this.attribute.ATTR_INPUT?.INHERITED == 1 ? true : false;
    }
  },
  data() {
    return {
      merge: {
        loading: false,
        dialog: false,
        selected: undefined,
        saving: false,
        items: []
      },
      importObj: {
        dialog: false,
        file: undefined,
        saving: false,
        importedList: null
      },
      refAction: [
        { text: "Delete", action: "delete", mustSelect: 1 },
        { text: "Export", action: "export" },
        { text: "Import", action: "import" },
        { text: "Merge", action: "merge", mustSelect: 2 }
      ],
      advOpt: {
        formatType: "S",
        options: [
          { text: "Standard Format", value: "S" },
          { text: "Custom Format", value: "C" }
        ],
        formatOpt: [
          { text: "Alphanumeric", value: "::ALPHANUMERIC::" },
          { text: "Alphabet only", value: "::ALPHABET::" },
          { text: "Numeric", value: "::NUMERIC::" },
          { text: "Email address", value: "::EMAIL::" }
        ],
        active: false
      },
      toggleAll: false,
      otherOpt: false,
      attribute: {},
      attr_ref: [],
      selected_ref: {},
      selectedRefArr: [],
      readOnly: false,
      loading: false,
      values1: {},
      fields: [
        { field: "ATTR_NAME", label: "Attribute Name", validation: ["mandatory"] },
        { field: "ATTR_LABEL", label: "Attribute Label" },
        {
          field: "OBJECT_TYPE",
          label: "Attribute Type",
          type: "L",
          control: "RADIO",
          lov: [
            { text: "Company", value: "COMPANY" },
            { text: "Contact", value: "CONTACT" }
          ],
          validation: ["mandatory"]
        },
        {
          field: "ATTR_TYPE",
          label: "Value Type",
          type: "L",
          cols: 6,
          lov: [
            { text: "Text", value: "T" },
            { text: "Number", value: "N" },
            { text: "List", value: "L" },
            { text: "List - Multiple Values", value: "LM" },
            { text: "Date", value: "D" }
          ],
          validation: ["mandatory"]
        },
        {
          field: "INPUT_TYPE",
          label: "Input Type",
          cols: 6,
          type: "L",
          validation: ["mandatory"],
          lov: []
        },
        {
          field: "ADOBE_SYNC",
          label: "Sync to Adobe Campaign",
          type: "L",
          control: "CHECKBOX"
        }
      ],
      inputTypeLovs: {
        T: [
          { text: "Text field", value: "TEXT" },
          { text: "Password", value: "PASSWORD" },
          { text: "Text area", value: "TEXTAREA" },
          { text: "Hidden", value: "HIDDEN" }
        ],
        N: [
          { text: "Text field", value: "TEXT" },
          { text: "Hidden", value: "HIDDEN" }
        ],
        L: [
          { text: "Radio Button", value: "RADIO" },
          { text: "Dropdown list", value: "SELECT" },
          { text: "Hidden", value: "HIDDEN" }
        ],
        LM: [
          { text: "Checkbox", value: "CHECKBOX" },
          { text: "Popup with Checkbox", value: "POPCHECKBOX" },
          { text: "Multiple select", value: "SELECTMULTI" },
          { text: "Hidden", value: "HIDDEN" }
        ]
      },
      options: [
        { text: "Mandatory", value: "M" },
        { text: "Accessible by QCers & Mgmt only", value: 524288 },
        { text: "Accessible by TM Lead only", value: 32 },
        { text: "Enable Timestamp", value: 64 },
        { text: "Call list search", value: 1 },
        { text: "Advanced search", value: 2 },
        { text: "Hide from Agent UI", value: 16384 },
        { text: "Enable History on Agent UI", value: 32768 }
      ]
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  methods: {
    mergeRef() {
      this.merge.saving = true;
      this.$axios({
        method: "post",
        url: "/lnp/db/design/mergeRef",
        data: {
          all_ref: this.selectedRefArr,
          sel_ref: this.merge.selected,
          attrId: this.attrId,
          projectId: this.projectId,
          campaignId: this.campaignId
        }
      })
        .then((res) => {
          this.resetSelectedRef();
          this.attr_ref = res.data.attr_ref;
          this.addNewRow();
          this.$store.commit("sendAlert", {
            msg: "Total Merge Count is " + res.data.totalmerge,
            color: "success"
          });
          this.merge.dialog = false;
          this.$emit("update", this.attrId);
        })
        .finally(() => {
          this.merge.saving = false;
        });
    },
    openMergeRef() {
      this.merge = {
        loading: true,
        dialog: true,
        selected: undefined,
        saving: false,
        items: []
      };

      this.$axios
        .get("/lnp/db/design/getRefMergeCnt", {
          params: {
            mergeId: this.selectedRefArr,
            attrId: this.attrId
          }
        })
        .then((res) => {
          for (let ref of Object.values(res.data)) {
            let text = this.attr_ref.find((e) => e.ATTR_REF_ID == ref.ATTR_REF_ID).ATTR_REF_VALUE;
            this.merge.items.push({ ...ref, text });
          }
        })
        .finally(() => {
          this.merge.loading = false;
        });
    },
    importRef() {
      let formData = new FormData();
      formData.append("file", this.importObj.file);
      formData.append("campaignId", this.campaignId);
      formData.append("attrId", this.attrId);

      this.importObj.saving = true;
      this.$axios
        .post("/lnp/db/design/importRef", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((res) => {
          if (res.data.imported_list) {
            this.attr_ref = this.attr_ref.filter((e) => e.ATTR_REF_ID);
            res.data.imported_list.forEach((e) =>
              this.attr_ref.push({
                ATTR_REF_ID: e.attr_ref_id,
                ATTR_REF_LABEL: "",
                ATTR_REF_VALUE: e.attr_ref_value,
                HIDDEN: false
              })
            );
            this.addNewRow();
            this.importObj.importedList = res.data.imported_list;
            this.$emit("update", this.attrId);
          }
        })
        .finally(() => {
          this.importObj.saving = false;
        });
    },
    deleteRef() {
      this.$root
        .$confirm("Confirmation", "Delete selected List Values?", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            this.$axios
              .delete("/lnp/db/design/deleteLOV", {
                params: {
                  ids: this.selectedRefArr,
                  attrId: this.attrId,
                  projectId: this.projectId,
                  campaignId: this.campaignId
                }
              })
              .then((res) => {
                if (res.data.attr_ref) {
                  this.resetSelectedRef();
                  this.attr_ref = res.data.attr_ref;
                  this.addNewRow();
                  this.$store.commit("sendAlert", {
                    msg: "List Values deleted",
                    color: "success"
                  });
                  this.$emit("update", this.attrId);
                }
              });
          }
        });
    },
    exportRef() {
      let domainName = this.$store.getters["auth/getApiHost"]["lnp_host"];
      let downloadUrl = `${domainName}/lnp/popup_export_ref_values.htm`;
      let param = `?jwt=${this.$store.state.auth.token}&ATTR_ID=${this.attrId}&campaignId=${this.campaignId}`;
      window.open(downloadUrl + param);
    },
    onRefAction(action) {
      switch (action) {
        case "delete":
          this.deleteRef();
          break;
        case "export":
          this.exportRef();
          break;
        case "import":
          this.importObj = {
            saving: false,
            dialog: true,
            file: undefined,
            importedList: null
          };
          break;
        case "merge":
          this.openMergeRef();
          break;
      }
    },
    onUpdateToggle() {
      this.selectedRefArr = [];
      for (let [key, ref] of Object.entries(this.selected_ref)) {
        if (ref) {
          this.selectedRefArr.push(key);
        }
      }
    },
    onToggleAll(type, e) {
      if (type == "hide") {
        this.attr_ref.forEach((ref) => {
          ref.HIDDEN = e;
        });
      } else {
        this.selected_ref = {};
        this.selectedRefArr = [];
        for (const ref of Object.values(this.attr_ref)) {
          this.selected_ref[ref.ATTR_REF_ID] = e;
          if (e) this.selectedRefArr.push(ref.ATTR_REF_ID);
        }
      }
    },
    addNewRow() {
      this.attr_ref.push({
        ATTR_REF_ID: null,
        ATTR_REF_LABEL: "",
        ATTR_REF_VALUE: "",
        HIDDEN: false
      });
    },
    onInputChange(field, val) {
      if (field == "ATTR_TYPE") {
        let lovSet = val;
        if (val == "D") lovSet = "N";
        this.fields.find((e) => e.field == "INPUT_TYPE").lov = this.inputTypeLovs[lovSet];
        this.values1.INPUT_TYPE = this.inputTypeLovs[lovSet][0];
      }
    },
    onFormSave() {
      this.$axios({
        method: "put",
        url: "/lnp/db/design/save",
        data: {
          data: { ...this.values1, otherOpt: this.otherOpt, attr_ref: this.attr_ref },
          attrId: this.attrId,
          projectId: this.projectId,
          campaignId: this.campaignId
        }
      })
        .then((res) => {
          if (res.data.error) {
            this.$store.commit("sendAlert", {
              msg: res.data.error,
              color: "error"
            });
          } else {
            if (res.data.attr_ref && this.attr_id) {
              this.attr_ref = res.data.attr_ref;
            }
            this.$emit("update", res.data.attr_id);
            this.$store.commit("sendAlert", {
              msg: "Attribute saved",
              color: "success"
            });
          }
        })
        .finally(() => {
          this.$refs.crudForm.saved();
        });
    },
    closeForm() {
      this.$emit("input", false);
    },
    setDefaultVal() {
      this.readOnly = false;
      this.selected = [];
      this.onInputChange("ATTR_TYPE", "T");
      this.values1 = {};
      this.values1.INPUT_TYPE = "TEXT";
      this.values1.ATTR_TYPE = "T";
      this.values1.OBJECT_TYPE = "COMPANY";
      this.values1.ADOBE_SYNC = "1";
      this.values1.ATTR_NAME = "";
      this.values1.ATTR_LABEL = "";

      this.values1.LABEL_NAME_1 = "";
      this.values1.LABEL_NAME_2 = "";
      this.values1.APPEND_LABEL = false;

      this.advOpt.formatType = "C";
      this.values1.INPUT_OPTION = "";
      this.values1.FORMAT = null;

      this.values1.QUERYBIT = [];
    },
    setupForm() {
      if (!this.projectId) {
        this.options.push({ text: "Pre-population", value: 131072 });
      } else {
        this.options.push({ text: "Add to DB for Reuse", value: 65536 });
      }
      for (const option of Object.values(this.options)) {
        if (this.inherited && !["M", 524288, 32, 64].includes(option.value)) {
          option.disabled = true;
        } else if (this.values1.QUERYBIT.includes(32768) && option.value == 32768) {
          //enable history cant be unchecked
          option.disabled = true;
        }
      }
      if (this.attrId) {
        for (const key of Object.keys(this.values1)) {
          if (key in this.attribute) {
            let val = this.attribute[key];
            if (key == "ATTR_TYPE") {
              this.onInputChange("ATTR_TYPE", val);
              //this.values1.INPUT_TYPE = this.attribute.ATTR_INPUT.INPUT_TYPE;
            } else if (key == "FORMAT") {
              if (this.advOpt.formatOpt.some((e) => e.value === val)) {
                this.advOpt.formatType = "S";
              } else {
                this.advOpt.formatType = "C";
              }
            }
            this.values1[key] = val;
          }
        }
      }
      this.resetSelectedRef();
      this.addNewRow();
    },
    resetSelectedRef() {
      this.toggleAll = false;
      this.selected_ref = {};
      this.selectedRefArr = [];
      for (const ref of Object.values(this.attr_ref)) {
        this.selected_ref[ref.ATTR_REF_ID] = false;
      }
    },
    load() {
      this.setDefaultVal();
      if (this.attrId) {
        this.loading = true;
        this.$axios
          .get("/lnp/db/design/get", {
            params: {
              campaignId: this.campaignId,
              projectId: this.projectId,
              attrId: this.attrId
            }
          })
          .then((res) => {
            this.attribute = res.data.attr;
            this.attr_ref = res.data.attr_ref ?? [];
            this.values1.QUERYBIT = res.data.queryBit;
            this.otherOpt = res.data.otherOpt;
            this.readOnly = res.data.read_only;
            this.setupForm();
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.setupForm();
      }
    }
  }
};
</script>
