<template>
  <div style="display: contents">
    <CRUDForm
      :persistent="false"
      ref="crudForm"
      :alwaysPostAll="true"
      @save="onFormSave"
      @close="closeForm"
      :formSize="800"
      :headerText="'Use ' + (projectId ? 'Campaign' : 'Database') + ' Attribute'"
      :loading="loading"
      :formActive="value"
    >
      <template v-slot:[`form.content`]="">
        <v-row class="py-5">
          <v-col cols="12" v-for="objectType in ['COMPANY', 'CONTACT']" :key="objectType">
            <v-autocomplete
              chips
              v-model="values[objectType]"
              :items="items[objectType]"
              outlined
              hide-selected
              hide-details
              :label="objectType == 'COMPANY' ? 'Company' : 'Contact'"
              multiple
              deletable-chips
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </template>
    </CRUDForm>
  </div>
</template>

<script>
import CRUDForm from "@/components/util/CRUD/CRUDForm";

export default {
  components: {
    CRUDForm
  },
  props: ["campaignId", "projectId", "value"],
  data() {
    return {
      loading: false,
      values: {},
      items: {
        CONTACT: [],
        COMPANY: []
      }
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  methods: {
    onFormSave() {
      this.$axios({
        method: "post",
        url: "/lnp/db/design/reuseAttr",
        data: {
          campaignId: this.campaignId,
          projectId: this.projectId,
          attrs: this.values
        }
      })
        .then(() => {
          this.$emit("update", true);
          this.$store.commit("sendAlert", {
            msg:
              "Attributes linked successfully from " + (this.projectId ? "Campaign" : "Database"),
            color: "success"
          });
        })
        .finally(() => {
          this.$refs.crudForm.saved();
        });
    },
    closeForm() {
      this.$emit("input", false);
    },
    load() {
      this.loading = true;
      this.values = {};
      this.$axios
        .get("/lnp/db/design/getReuseAttr", {
          params: {
            campaignId: this.campaignId,
            projectId: this.projectId
          }
        })
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
