<template>
  <div style="display:content">
    <v-dialog
      scrollable
      :value="value"
      @keydown.esc="closeForm"
      @input="closeForm"
      max-width="600px"
    >
      <v-card :loading="loading">
        <v-toolbar short flat>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Folder permission for {{ className }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="px-0">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in items" :key="i">
                  <td>
                    <v-icon>{{
                      item["type"] == "group" ? "mdi-account-multiple" : "mdi-account"
                    }}</v-icon>
                    {{ item["name"] }}
                  </td>
                  <td style="width: 50px">
                    <v-btn icon small @click="onActionClick('access', item)" :disabled="saving">
                      <v-icon>{{ item["write_access"] ? "mdi-pencil" : "mdi-eye" }}</v-icon>
                    </v-btn>
                  </td>
                  <td style="width: 50px">
                    <v-btn icon small @click="onActionClick('delete', item)" :disabled="saving">
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td>
                    <v-autocomplete
                      dense
                      v-model="newItem"
                      :items="lovs"
                      :loading="searching"
                      return-object
                      item-value="id"
                      item-text="name"
                      outlined
                      hide-no-data
                      hide-selected
                      hide-details
                      label="Add people"
                      placeholder="Start searching by typing 2 or more chars"
                      @update:search-input="search"
                    >
                    </v-autocomplete>
                  </td>
                  <td style="width: 50px">
                    <v-btn
                      icon
                      small
                      @click="onActionClick('access_new', newItem)"
                      :disabled="saving"
                    >
                      <v-icon>{{ newItem["write_access"] ? "mdi-pencil" : "mdi-eye" }}</v-icon>
                    </v-btn>
                  </td>
                  <td style="width: 50px">
                    <v-btn icon small @click="onActionClick('add', newItem)" :disabled="saving">
                      <v-icon>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["classId", "value"],
  data() {
    return {
      source: undefined,
      lovs: [],
      searching: false,
      className: "",
      loading: false,
      saving: false,
      items: [],
      newItem: {}
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (v) this.load();
      }
    }
  },
  methods: {
    onActionClick(mode, item) {
      if (mode == "access_new") {
        item["write_access"] = !item["write_access"];
      } else {
        this.saving = true;
        this.$axios({
          method: "post",
          url: "/lnp/update_access",
          data: {
            mode: mode,
            class_id: this.classId,
            data: item
          }
        })
          .then(() => {
            if (mode == "access") {
              item["write_access"] = !item["write_access"];
            } else {
              if (mode == "add") {
                this.newItem = {
                  write_access: false
                };
              }
              this.fetch();
            }
          })
          .finally(() => {
            this.saving = this.building = false;
          });
      }
    },
    search(val) {
      if (val === null || val.length < 2) {
        this.lovs = [];
        return;
      }
      this.source && this.source.cancel("Operation canceled due to new request.");
      this.source = axios.CancelToken.source();
      this.searching = true;
      this.$axios
        .get("/lnp/search_people", {
          params: {
            term: val,
            class_id: this.classId
          },
          cancelToken: this.source.token
        })
        .then((res) => {
          if (res.data) {
            this.lovs = res.data;
          }
        })
        .finally(() => {
          this.searching = false;
        });
    },
    closeForm() {
      this.$emit("input", false);
    },
    fetch() {
      this.loading = true;
      this.$axios
        .get("/lnp/get_class_perm", {
          params: {
            class_id: this.classId
          }
        })
        .then((res) => {
          this.className = res.data.class.CLASS_NAME;
          this.items = res.data.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    load() {
      this.items = {};
      this.newItem = {
        write_access: false
      };
      this.fetch();
    }
  }
};
</script>
